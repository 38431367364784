<script>
export default {
  name: "aging-patient-table-table",
  props: {
    records: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
  },
};
</script>

<template>
  <div class="table-responsive p-0">
    <table class="table align-items-center mb-0">
      <thead>
        <tr>
          <th
            class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
          >
            Insurance
          </th>
          <th
            class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
          >
            Patient Name
          </th>
          <th
            class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
          >
            Phone
          </th>
          <th
            class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
          >
            1-30 days
          </th>
          <th
            class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
          >
            31-60 days
          </th>
          <th
            class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
          >
            61-90 days
          </th>
          <th
            class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
          >
            > 90 days
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in records" :key="item.id + index">
          <td class="ps-4 text-xs text-left">
            {{ item.name }}
          </td>
          <td class="ps-4 text-xs text-left">
            {{ item.first_name }} {{ item.middle_name }}
            {{ item.last_name }}
          </td>
          <td class="ps-4 text-xs text-left">{{ item.phone }}</td>
          <td class="ps-4 text-xs text-left">{{ item.cluster_a }}</td>
          <td class="ps-4 text-xs text-left">{{ item.cluster_b }}</td>
          <td class="ps-4 text-xs text-left">{{ item.cluster_c }}</td>
          <td class="ps-4 text-xs text-left">{{ item.cluster_d }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped></style>
