<template>
  <el-popover placement="bottom-start" :width="1000" :visible="filter_visible">
    <div class="card">
      <div class="card-body p-3">
        <div class="popover-header">Filter List</div>
        <el-form
          ref="filterFormRef"
          label-width="0px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
        >
          <div class="row">
            <div class="col-md-12" v-if="show_search_filter">
              <el-form-item :label="search_label" prop="search_query">
                <el-input
                  v-model="filter_params.search_query"
                  class="w-100"
                  :placeholder="search_label"
                  clearable
                >
                </el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row" v-if="show_date_filter">
            <el-form-item :label="props.date_range_label" prop="search_query">
              <el-date-picker
                v-model="filter_params.date_range"
                type="daterange"
                unlink-panels
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                :shortcuts="shortcuts"
                :size="size"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
          </div>
          <div class="row">
            <div class="col-md-6" v-if="props.show_doctor_filter">
              <el-form-item label="Doctor Name" prop="doctor_id">
                <el-select-v2
                  v-model="filter_params.doctor_id"
                  filterable
                  remote
                  clearable
                  :options="doctors"
                  placeholder="Please select a doctor"
                  style="width: 100%"
                  :remote-method="loadDoctors"
                  :loading="isLoading"
                >
                </el-select-v2>
              </el-form-item>
            </div>
            <div class="col-md-6" v-if="props.show_patient_filter">
              <el-form-item label="Patient Name" prop="patient_id">
                <el-select-v2
                  v-model="filter_params.patient_id"
                  filterable
                  remote
                  clearable
                  :options="patients"
                  placeholder="Please select a patient"
                  style="width: 100%"
                  :remote-method="loadPatients"
                  :loading="isLoading"
                >
                </el-select-v2>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4" v-if="props.show_insurance_filter">
              <el-form-item label="Insurance" prop="insurance_id">
                <el-select-v2
                  v-model="filter_params.insurance_id"
                  filterable
                  remote
                  clearable
                  :options="insurances"
                  placeholder="Please select insurance"
                  style="width: 100%"
                  :remote-method="loadInsurances"
                  :loading="isLoading"
                >
                </el-select-v2>
              </el-form-item>
            </div>
            <div class="col-md-4" v-if="props.show_employer_filter">
              <el-form-item label="Employer/Scheme" prop="employer_id">
                <el-select-v2
                  clearable
                  v-model="filter_params.employer_id"
                  filterable
                  remote
                  :options="employers"
                  placeholder="Please select a employer"
                  style="width: 100%"
                  :remote-method="loadEmployers"
                  :loading="isLoading"
                >
                </el-select-v2>
              </el-form-item>
            </div>
            <div class="col-md-4" v-if="props.show_hospital_filter">
              <el-form-item label="Hospital" prop="hospital_id">
                <el-select-v2
                  v-model="filter_params.hospital_id"
                  filterable
                  clearable
                  remote
                  :options="hospitals"
                  placeholder="Please select a hospital"
                  style="width: 100%"
                  :remote-method="loadHospitals"
                  :loading="isLoading"
                >
                </el-select-v2>
              </el-form-item>
            </div>
            <div class="row">
              <div class="col-md-3" v-if="props.show_appointment_type_filter">
                <el-form-item label="Appointment type" prop="appointment_type">
                  <el-select
                    clearable
                    class="w-100"
                    placeholder="Select appointment type"
                    v-model="filter_params.appointment_type"
                  >
                    <el-option label="Out Patient" value="out-patient" />
                    <el-option label="Day Case" value="day-case" />
                    <el-option label="In Patient" value="in-patient" />
                  </el-select>
                </el-form-item>
              </div>
              <div class="col-md-3" v-if="props.show_invoice_status_filter">
                <el-form-item label="Invoice Status" prop="invoice_status">
                  <el-select
                    clearable
                    class="w-100"
                    placeholder="Select invoice status"
                    v-model="filter_params.invoice_status"
                  >
                    <el-option label="Draft" value="draft" />
                    <el-option label="Approved" value="approved" />
                    <el-option label="WithDrawn" value="withdrawn" />
                    <el-option label="Bad Debt" value="bad_debt" />
                  </el-select>
                </el-form-item>
              </div>
              <div class="col-md-3" v-if="props.show_payment_status_filter">
                <el-form-item label="Payment Status" prop="payment_status">
                  <el-select
                    clearable
                    class="w-100"
                    placeholder="Select invoice payment status"
                    v-model="filter_params.payment_status"
                  >
                    <el-option label="Paid" value="paid" />
                    <el-option label="In complete" value="partially_paid" />
                    <el-option label="No Payment" value="un_paid" />
                  </el-select>
                </el-form-item>
              </div>
              <div class="col-md-3" v-if="props.show_report_format_filter">
                <el-form-item label="Report Format" prop="report_format">
                  <el-select
                    clearable
                    class="w-100"
                    placeholder="Select Report Format"
                    v-model="filter_params.report_format"
                  >
                    <el-option label="Default" value="default" />
                    <el-option label="Client Format" value="client" />
                  </el-select>
                </el-form-item>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4" v-if="props.show_payment_mode_filter">
                <el-form-item label="Payment Mode" prop="payment_mode_id">
                  <el-select-v2
                    v-model="filter_params.payment_mode_id"
                    filterable
                    clearable
                    remote
                    :options="payment_modes"
                    placeholder="Please select payment mode"
                    style="width: 100%"
                    :remote-method="loadPaymentModes"
                    :loading="isLoading"
                  >
                  </el-select-v2>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <el-button
                size="default"
                type="success"
                style="width: 100%"
                @click="filterList"
                >Filter</el-button
              >
            </div>
            <div class="col-sm-4">
              <el-button
                size="default"
                type="danger"
                style="width: 100%"
                @click="cancelFilter"
                >Reset</el-button
              >
            </div>
            <div class="col-sm-4">
              <el-button
                size="default"
                type="info"
                style="width: 100%"
                @click="closeFilter"
                >Close</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <template #reference>
      <el-button type="primary" @click="filter_visible = !filter_visible"
        ><i class="fa fa-search text-white me-1" aria-hidden="true"></i
        >Search</el-button
      >
    </template>
  </el-popover>
</template>
<script>
import { reactive, ref } from "vue";
import {
  fetchEmployers,
  fetchHospitals,
  fetchInsurances,
  fetchPaymentModes,
} from "../../api/services/services";
import { fetchUsers, fetchPatients } from "../../api/services/users";
import { useRoute } from "vue-router";
const employers = ref([]);
const doctors = ref([]);
const patients = ref([]);
const hospitals = ref([]);
const insurances = ref([]);
const payment_modes = ref([]);
const isLoading = false;
const filter_visible = ref(false);
const shortcuts = [
  {
    text: "Last week",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      return [start, end];
    },
  },
  {
    text: "Last month",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      return [start, end];
    },
  },
  {
    text: "Last 3 months",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      return [start, end];
    },
  },
  {
    text: "Today",
    value: () => {
      const end = new Date();
      const start = new Date();
      return [start, end];
    },
  },
];
export default {
  name: "table-filter",
  props: {
    show_search_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_date_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    payment_status_label: {
      type: String,
      required: false,
      default: "Select invoice status",
    },
    date_range_label: {
      type: String,
      required: false,
      default: "Date Range",
    },
    search_label: {
      type: String,
      required: false,
      default: "Search Records",
    },
    show_insurance_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_employer_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_hospital_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_doctor_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_payment_status_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_report_format_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_patient_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_invoice_status_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_appointment_type_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    show_payment_mode_filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    filter_init: {
      type: Object,
      required: false,
    },
    filterCallBack: {
      type: Function,
      required: true,
    },
  },

  watch: {
    filter_visible: {
      handler: function (newValue) {
        if (newValue) {
          this.loadPatients("");
          this.loadInsurances("");
          this.loadEmployers("");
          this.loadDoctors("");
          this.loadHospitals("");
          this.loadPaymentModes("");
        }
      },

      deep: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const size = "large";
    const filter_params = reactive({
      search_query: props.filter_init.search_query ?? "",
      insurance_id:
        props.filter_init.insurance_id ?? route.params?.insurance_id,
      employer_id: props.filter_init.employer_id ?? null,
      hospital_id: props.filter_init.hospital_id ?? null,
      patient_id: props.filter_init.patient_id ?? null,
      payment_mode_id: props.filter_init.payment_mode_id ?? null,
      doctor_id: props.filter_init.doctor_id ?? null,
      date_range: props.filter_init.date_range ?? null,
      payment_status: props.filter_init.payment_status ?? "",
      invoice_status: props.filter_init.invoice_status ?? "",
      report_format: props.filter_init.report_format ?? "default",
      appointment_type: props.filter_init.appointment_type ?? null,
    });

    const cancelFilter = () => {
      filter_params.search_query = "";
      filter_params.insurance_id = "";
      filter_params.employer_id = "";
      filter_params.hospital_id = "";
      filter_params.patient_id = "";
      filter_params.payment_mode_id = "";
      filter_params.doctor_id = "";
      filter_params.date_range = "";
      filter_params.appointment_type = "";
      filter_params.payment_status = "";
      filter_params.invoice_status = "";
      filter_params.report_format = "";
      filter_visible.value = false;
      props.filterCallBack(filter_params);
    };
    const closeFilter = () => {
      filter_params.search_query = "";
      filter_params.insurance_id = "";
      filter_params.employer_id = "";
      filter_params.hospital_id = "";
      filter_params.patient_id = "";
      filter_params.payment_mode_id = "";
      filter_params.doctor_id = "";
      filter_params.date_range = "";
      filter_params.appointment_type = "";
      filter_params.payment_status = "";
      filter_params.invoice_status = "";
      filter_params.report_format = "";
      filter_visible.value = false;
    };
    const filterList = () => {
      filter_visible.value = false;
      props.filterCallBack(filter_params);
    };

    const loadPatients = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchPatients(entity_params);

        const tempPatients = response.data.data.map((patient) => ({
          value: `${patient.id}`,
          label: `${
            patient.first_name +
            " " +
            patient.middle_name +
            " " +
            patient.last_name
          }`,
        }));
        patients.value = tempPatients;
      } catch (error) {
        console.log(error);
      }
    };
    const loadDoctors = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query, role: "doctor" };
      try {
        const response = await fetchUsers(entity_params);

        const tempDoctors = response.data.data.map((doctor) => ({
          value: `${doctor.id}`,
          label: `${doctor.first_name + " " + doctor.last_name}`,
        }));
        doctors.value = tempDoctors;
      } catch (error) {
        console.log(error);
      }
    };
    const loadInsurances = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchInsurances(entity_params);

        const tempInsurances = response.data.data.map((nsurance) => ({
          value: `${nsurance.id}`,
          label: `${nsurance.name}`,
        }));
        insurances.value = tempInsurances;
      } catch (error) {
        console.log(error);
      }
    };
    const loadEmployers = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchEmployers(entity_params);

        const tempEmloyers = response.data.data.map((emloyers) => ({
          value: `${emloyers.id}`,
          label: `${emloyers.name}`,
        }));
        employers.value = tempEmloyers;
      } catch (error) {
        console.log(error);
      }
    };
    const loadHospitals = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchHospitals(entity_params);

        const tempHospitals = response.data.data.map((hospital) => ({
          value: `${hospital.id}`,
          label: `${hospital.name}`,
        }));
        hospitals.value = tempHospitals;
      } catch (error) {
        console.log(error);
      }
    };
    const loadPaymentModes = async (query) => {
      const entity_params = ref();
      entity_params.value = { search_query: query };
      try {
        const response = await fetchPaymentModes(entity_params);

        const tempModes = response.data.data.map((mode) => ({
          value: `${mode.id}`,
          label: `${mode.name}`,
        }));
        payment_modes.value = tempModes;
      } catch (error) {
        console.log(error);
      }
    };

    return {
      loadPaymentModes,
      loadPatients,
      loadInsurances,
      loadEmployers,
      loadDoctors,
      loadHospitals,
      patients,
      doctors,
      insurances,
      employers,
      hospitals,
      payment_modes,
      isLoading,
      filter_params,
      filterList,
      cancelFilter,
      closeFilter,
      props,
      size,
      filter_visible,
      shortcuts,
    };
  },
};
</script>
<style scoped>
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
</style>
