<script setup>
import Loader from "@/views/components/Loader.vue";
import { ref } from "vue";
import {
  fetchAgingSummary,
  downloadAgingSummaryPdf,
  downloadAgingSummaryExcel,
} from "@/api/services/reports";
import { ElMessage } from "element-plus";
import AgingPatientTable from "@/views/components/AgingPatientTable.vue";
import AgingInsuranceTable from "@/views/components/AgingInsuranceTable.vue";
const tableData = ref([]);
const isLoading = ref(false);
const group_by = ref("insurance");
const group_by_options = [
  {
    value: "insurance",
    label: "Group By Insurance",
  },
  {
    value: "patient",
    label: "Group By Patient",
  },
];
const fetchData = async (filter) => {
  isLoading.value = true;
  let entity_params = {};
  try {
    if (filter === undefined) {
      entity_params = ref({});
    } else {
      entity_params = { ...filter };
    }
    entity_params = { ...entity_params,...{ group_by: group_by.value } };
    tableData.value.splice(0);
    const response = await fetchAgingSummary(entity_params);
    tableData.value = response.report?.map((item) => item);
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};
const downloadExcel = async (filter) => {
  isLoading.value = true;
  let entity_params = {};
  try {
    if (filter === undefined) {
      entity_params = ref({});
    } else {
      entity_params = { ...filter };
    }
    ElMessage({
      type: "info",
      message: "Your report will be downloading shortly. Please wait...",
    });
    entity_params ={ ...entity_params,...{ orderBy: 'id',sortedBy:'desc',group_by: group_by.value } };
    await downloadAgingSummaryExcel(entity_params)
      .then((Response) => {
        let fileURL = window.URL.createObjectURL(
          new Blob([Response], {
            type: "application/vnd.ms-excel",
          })
        );
        let fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          "aging_summary_" + Math.round(+new Date() / 1000) + ".xlsx"
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch((err) => {
        console.log(err);
        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      });
  } catch (error) {
    console.log(error);
    ElMessage({
      type: "error",
      message: "Whoops! something went wrong downloading report",
    });
  } finally {
    isLoading.value = false;
  }
};
const downloadPdf = async (filter) => {
  isLoading.value = true;
  let entity_params = {};
  try {
    if (filter === undefined) {
      entity_params = ref({});
    } else {
      entity_params = { ...filter };
    }
    ElMessage({
      type: "info",
      message: "Your report will be downloading shortly. Please wait...",
    });
    entity_params = { ...entity_params,...{ orderBy: 'id',sortedBy:'desc',group_by: group_by.value } };
    await downloadAgingSummaryPdf(entity_params)
      .then((Response) => {
        let fileURL = window.URL.createObjectURL(
          new Blob([Response], {
            type: "application/pdf",
          })
        );
        let fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          "aging_summary_" + Math.round(+new Date() / 1000) + ".pdf"
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch((err) => {
        console.log(err);

        ElMessage({
          type: "error",
          message: "Whoops! something went wrong downloading report",
        });
      });
  } catch (error) {
    console.log(error);
    ElMessage({
      type: "error",
      message: "Whoops! something went wrong downloading report",
    });
  } finally {
    isLoading.value = false;
  }
};
function onInsuranceClicked(insurance_id) {
  group_by.value = "patient";
  fetchData({ insurance_id: insurance_id });
}

fetchData(undefined, 1);
</script>

<template>
  <div v-if="isLoading"><Loader /></div>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex align-items-center gap-2">
        <i class="ni ni-calendar-grid-58 text-primary text-md opacity-10"></i>
        <h6>Aging Summary</h6>
      </div>
      <div class="cta">
        <el-select
          v-model="group_by"
          placeholder="Select"
          style="width: 240px"
          @change="fetchData()"
        >
          <el-option
            v-for="item in group_by_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-button-group>
          <el-button type="danger" @click="downloadPdf"
            ><i class="fa fa-file-pdf-o text-white me-1" aria-hidden="true"></i
            >PDF</el-button
          >
          <el-button type="success" @click="downloadExcel"
            ><i
              class="fa fa-file-excel-o text-white me-1"
              aria-hidden="true"
            ></i
            >Excel</el-button
          >
        </el-button-group>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div v-if="tableData.length === 0">
        <empty-state-table />
      </div>
      <aging-insurance-table
        v-if="group_by === 'insurance'"
        :records="tableData"
        :refresh="fetchData"
        loading
        :on-insurance-clicked="onInsuranceClicked"
      />
      <aging-patient-table
        v-else
        :records="tableData"
        :refresh="fetchData"
        loading
      />
    </div>
    <div class="card-footer d-flex justify-content-center mb-3"></div>
  </div>
</template>

<style scoped>
.card-body {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}

h6 {
  margin: 0;
}

.ni-calendar-grid-58 {
  font-size: 25px;
}
</style>
