<script>
import {formatNumber} from "chart.js/helpers";

export default {
  name: "aging-insurance-table",
  methods: {formatNumber},
  props: {
    records: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    onInsuranceClicked: {
      type: Function,
      required: true,
    },
  },
};
</script>

<template>
  <div class="table-responsive p-0">
    <table class="table align-items-center mb-0">
      <thead>
        <tr>
          <th
            class="text-left  text-uppercase text-secondary text-xxs font-weight-bolder"
          >
            Insurance Name
          </th>
          <th
            class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
          >
            1-30 days
          </th>
          <th
            class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
          >
            31-60 days
          </th>
          <th
            class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
          >
            61-90 days
          </th>
          <th
            class="text-left text-uppercase text-secondary text-xxs font-weight-bolder"
          >
            > 90 days
          </th>
          <th>Totals</th>
        </tr>
      </thead>
      <tbody>
      <tr>
        <td class="ps-4 text-sm text-left text-bold">Totals</td>
        <td class="ps-4 text-sm text-left text-bold">{{ formatNumber(records.reduce((sum, item) => sum + (item.cluster_a || 0), 0))}}</td>
        <td class="ps-4 text-sm text-left text-bold">{{ formatNumber(records.reduce((sum, item) => sum + (item.cluster_b || 0), 0))}}</td>
        <td class="ps-4 text-sm text-left text-bold">{{ formatNumber(records.reduce((sum, item) => sum + (item.cluster_c || 0), 0))}}</td>
        <td class="ps-4 text-sm text-left text-bold">{{ formatNumber(records.reduce((sum, item) => sum + (item.cluster_d || 0), 0))}}</td>
      </tr>
        <tr v-for="(item, index) in records" :key="item.id + index">
          <td class="ps-4 text-xs text-left">
            <a href="#" @click="onInsuranceClicked(item.id)">{{ item.name }}</a>
          </td>
          <td class="ps-4 text-xs text-left">{{ formatNumber(item.cluster_a) }}</td>
          <td class="ps-4 text-xs text-left">{{ formatNumber(item.cluster_b) }}</td>
          <td class="ps-4 text-xs text-left">{{ formatNumber(item.cluster_c) }}</td>
          <td class="ps-4 text-xs text-left">{{ formatNumber(item.cluster_d) }}</td>
          <td class="ps-4 text-sm text-left text-bold">{{formatNumber(item.cluster_a+item.cluster_b+item.cluster_c+item.cluster_d)}}</td>
        </tr>
      </tbody>

    </table>
  </div>
</template>

<style scoped></style>
