<template>
  <div class="modal-overlay">
    <div class="card" @click.stop>
      <div class="card-header">
        <i class="fa fa-invoice-o text-primary me-2" />Update Status
      </div>
      <hr class="horizontal dark m-0" />

      <el-form
        ref="invoiceStatusFormRef"
        :model="invoiceStatusForm"
        :rules="rules"
        label-width="120px"
        size=""
        status-icon
        :scroll-to-error="true"
        label-position="top"
        :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="mb-3">
            <el-form-item label="Invoice Status" prop="name">
              <el-select
                class="w-100"
                placeholder="Select"
                v-model="invoiceStatusForm.status"
              >
                <el-option label="Draft" value="draft" />
                <el-option label="Approved" value="approved" />
                <el-option label="WithDrawn" value="withdrawn" />
                <el-option label="Bad Debt" value="bad_debt" />
              </el-select>
            </el-form-item>
          </div>
          <div class="mb-3">
            <el-form-item label="Comment" prop="partial_write_off">
              <el-checkbox
                label="Apply Partial write off"
                v-model="invoiceStatusForm.partial_write_off"
                value="partial_write_off"
              />
            </el-form-item>
          </div>

          <div class="mb-3" v-if="invoiceStatusForm.partial_write_off">
            <el-form-item label="Write off Amount" prop="write_off_amount">
              <el-input
                v-model="invoiceStatusForm.write_off_amount"
                style="width: 100%"
                placeholder="Please add write off amount"
              />
            </el-form-item>
          </div>
          <div class="mb-3">
            <el-form-item label="Comment" prop="comment">
              <el-input v-model="invoiceStatusForm.comment" type="textarea" />
            </el-form-item>
          </div>
        </div>
        <div class="card--footer">
          <button
            class="btn mb-0 btn-danger btn-xs"
            @click="$emit('close-modal')"
          >
            Cancel
          </button>
          <el-button
            type="success"
            :loading="isLoading"
            @click="submitForm(invoiceStatusFormRef)"
            >Submit</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="close bg-danger" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import {
  createInvoiceStatus,
  createBulkInvoiceStatus,
} from "../../api/services/accounts";
import { ElNotification } from "element-plus";
const invoiceStatusFormRef = ref();
const isLoading = ref(false);
export default {
  name: "update invoice status",
  props: {
    invoice: {
      type: Object,
      required: false,
    },
    invoices: {
      type: Array,
      required: false,
    },
    multiple: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const invoiceStatusForm = reactive({
      id: props?.invoice?.id || 0,
      status: props?.invoice?.status || "",
      ids: props?.invoices || [],
      comment: "",
      partial_write_off: false,
      write_off_amount: "",
    });

    const rules = reactive({
      status: [
        {
          required: true,
          message: "Please select status",
          trigger: ["blur", "change"],
        },
      ],
      comment: [
        {
          required:
            invoiceStatusForm.status === "withdrawn" ||
            invoiceStatusForm.status === "bad_debt",
          message: "Please input a comment",
          trigger: ["blur", "change"],
        },
      ],
      write_off_amount: [
        {
          required: invoiceStatusForm.partial_write_off === true,
          message: "Please input a amount",
          trigger: ["blur", "change"],
        },
      ],
    });

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid) => {
        if (valid) {
          isLoading.value = true;

          if (props.multiple) {
            const formData = new FormData();
            formData.append(
              "invoice_ids",
              JSON.stringify(invoiceStatusForm.ids)
            );
            formData.append("status", invoiceStatusForm.status);
            formData.append("comment", invoiceStatusForm.comment);
            formData.append("amount", invoiceStatusForm.write_off_amount);
            formData.append(
              "is_partial",
              invoiceStatusForm.partial_write_off ? 1 : 0
            );

            createBulkInvoiceStatus(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh-invoices");
                  emit("close-modal");
                } else {
                  Object.values(data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                  );
                }
              })
              .catch((err) => {
                isLoading.value = false;
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                });
              })
              .finally(() => {
                isLoading.value = false;
              });
          } else {
            const formData = new FormData();
            formData.append("invoice_id", invoiceStatusForm.id);
            formData.append("status", invoiceStatusForm.status);
            formData.append("comment", invoiceStatusForm.comment);
            formData.append(
              "write_off_amount",
              invoiceStatusForm.write_off_amount
            );
            formData.append(
              "partial_write_off",
                invoiceStatusForm.partial_write_off ? 1 : 0
            );
            createInvoiceStatus(formData)
              .then((data) => {
                if (data.success) {
                  ElNotification({
                    title: "Success",
                    message: data.message,
                    type: "success",
                  });
                  emit("refresh-invoices");
                  emit("close-modal");
                } else {
                  Object.values(data.errors).forEach((val) =>
                    ElNotification({
                      title: "Error",
                      message: val[0],
                      type: "error",
                    })
                  );
                }
              })
              .catch((err) => {
                isLoading.value = false;
                ElNotification({
                  title: "Error",
                  message: err.message,
                  type: "error",
                });
              })
              .finally(() => {
                isLoading.value = false;
              });
          }
        }
      });
    };
    return {
      submitForm,
      rules,
      invoiceStatusForm,
      invoiceStatusFormRef,
      isLoading,
      props,
    };
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 7px;
  width: 450px;
  height: 500px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  color: #fff;
  border: 2px solid #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}

input {
  border-radius: 6px;
}
</style>
